
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ConfigFakeDexIndicationController } from "@/app/ui/controllers/ConfigFakeDexIndicationController";
import { ApiStateData } from "@/domain/entities/Api";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { FlagsPermissionConfigFakeDexIndication } from "@/feature-flags/flags-permission-config-fake-dex-indication";
import { Vue } from "vue-class-component";

export default class DetailConfigFakeIndication extends Vue {
  get isEditAble() {
    return FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable.isEnabled();
  }

  goBack() {
    this.$router.push("/customer-service/config-fake-dex-indication");
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get sourceDex() {
    return this.detailData.data.dfdcType === "source_dex";
  }

  apiDetailData = new ApiStateData();
  detailData = new ResponsePayloadV2();

  async fetchDetail() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await ConfigFakeDexIndicationController.getDetail(
        this.id
      );
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }

  goToEdit() {
    this.$router.push(
      `/customer-service/config-fake-dex-indication/${this.id}/edit`
    );
  }
}
